var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-container",
    {
      attrs: { icon: "mdi-merge", title: "Junções" },
      scopedSlots: _vm._u([
        {
          key: "tools",
          fn: function() {
            return [
              _vm.isAnima() && _vm.user.is_master
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          loading: _vm.loadindRefreshStudents,
                                          large: "",
                                          icon: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            $event.stopPropagation()
                                            return _vm.refreshStudents()
                                          }
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          btn: "",
                                          color: "red darken-1"
                                        }
                                      },
                                      [_vm._v("mdi-refresh-circle")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        26159846
                      )
                    },
                    [_c("span", [_vm._v(" Atualizar Alunos ")])]
                  )
                : _vm._e(),
              _c("emc-excel-import-icon", {
                attrs: { param: "joins-insert" },
                on: {
                  onCompleted: function($event) {
                    return _vm.getData()
                  }
                }
              }),
              _vm.isAnima()
                ? _c("s-icon-report-download-optimized", {
                    attrs: { report: "11", "show-message": "" }
                  })
                : _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        color: "success darken-1",
                                        icon: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          return _vm.download(11)
                                        }
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_c("v-icon", [_vm._v("mdi-microsoft-excel")])],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [_c("span", [_vm._v(" Baixar relatório ")])]
                  ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    color: "success darken-1",
                                    icon: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      _vm.showFormIndividual = true
                                    }
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v("mdi-plus")])],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v(" Cadastrar Nova Junção ")])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: { color: "indigo darken-1", icon: "" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      _vm.showFormBatch = true
                                    }
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c("v-icon", [
                                _vm._v("mdi-table-column-plus-before")
                              ])
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v(" Cadastrar Junção em Lote ")])]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("sys-register-batch-join", {
        attrs: { show: _vm.showFormBatch },
        on: {
          onCompleted: function($event) {
            return _vm.getData()
          },
          "update:show": function($event) {
            _vm.showFormBatch = $event
          }
        }
      }),
      _c("sys-join-filter", {
        attrs: { show: _vm.showFilter },
        on: {
          "update:show": function($event) {
            _vm.showFilter = $event
          },
          filter: function($event) {
            return _vm.filter($event)
          }
        }
      }),
      _c("emc-table-items", {
        attrs: {
          headers: _vm.headers,
          params: _vm.params,
          collections: _vm.collections,
          meta: _vm.meta,
          itemDelete: _vm.itemDelete,
          itemEdit: _vm.model,
          loading: _vm.loading,
          showAppend: "",
          label: "Pesquisar por curso, turma, disciplina ou código da junção..."
        },
        on: {
          "update:params": function($event) {
            _vm.params = $event
          },
          "update:collections": function($event) {
            _vm.collections = $event
          },
          "update:meta": function($event) {
            _vm.meta = $event
          },
          "update:itemDelete": function($event) {
            _vm.itemDelete = $event
          },
          "update:item-delete": function($event) {
            _vm.itemDelete = $event
          },
          "update:itemEdit": function($event) {
            _vm.model = $event
          },
          "update:item-edit": function($event) {
            _vm.model = $event
          }
        },
        scopedSlots: _vm._u(
          [
            _vm.acesso.instituicao.educational_group_id == 14
              ? {
                  key: "actions",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              icon: "",
                                              disabled: !item || !item.code
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                return _vm.showRemoveTotvs(item)
                                              }
                                            }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "red" } },
                                          [_vm._v("mdi-delete-circle")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c("span", [
                            _vm._v(" Remover item integrado no Totvs ")
                          ])
                        ]
                      )
                    ]
                  }
                }
              : null,
            {
              key: "append",
              fn: function() {
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        color: _vm.payloadFilter
                                          ? "primary"
                                          : "grey",
                                        icon: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          _vm.showFilter = true
                                        }
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("v-icon", { attrs: { btn: "" } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.payloadFilter
                                          ? "mdi-filter-menu-outline"
                                          : "mdi-filter-outline"
                                      )
                                    )
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [_c("span", [_vm._v(" Demais Filtros ")])]
                  )
                ]
              },
              proxy: true
            },
            {
              key: "item.id",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(" " + _vm._s(item.id) + " "),
                  _c("emc-util-icon-category", { attrs: { item: item } }),
                  _c("small", [
                    _vm.getTotalStudents(item) > 0
                      ? _c("strong", [
                          _c("br"),
                          _vm._v(
                            " (" +
                              _vm._s(_vm.getTotalStudents(item)) +
                              " Alunos) "
                          )
                        ])
                      : _vm._e(),
                    _vm.settings.allow_replicate_teacher_join
                      ? _c("span", [
                          _c("br"),
                          _vm._v(
                            " Replica Professor: " +
                              _vm._s(item.replicate_teacher ? "Sim" : "Não") +
                              " "
                          )
                        ])
                      : _vm._e(),
                    item.code
                      ? _c("small", [
                          _c("br"),
                          _vm._v("[Código: " + _vm._s(item.code) + " ]")
                        ])
                      : _vm._e()
                  ])
                ]
              }
            },
            {
              key: "item.campuses",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm.isUniqueCampuses(item)
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "span",
                                      _vm._g(
                                        _vm._b({}, "span", attrs, false),
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          _vm._s(
                                            item.grids[0].group.campus.initials
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(item.grids[0].group.campus.name) +
                                " "
                            )
                          ])
                        ]
                      )
                    : _c(
                        "ul",
                        {
                          staticStyle: {
                            padding: "0",
                            margin: "0",
                            "list-style": "none"
                          },
                          attrs: { type: "none" }
                        },
                        _vm._l(item.grids, function(grid) {
                          return _c(
                            "li",
                            { key: grid.id },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "span",
                                              _vm._g(
                                                _vm._b(
                                                  {},
                                                  "span",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    grid.group.campus.initials
                                                  )
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      " " + _vm._s(grid.group.campus.name) + " "
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        }),
                        0
                      )
                ]
              }
            },
            {
              key: "item.courses",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm.isUniqueCourses(item)
                    ? _c("span", [
                        _vm._v(
                          " " + _vm._s(item.grids[0].group.course.name) + " "
                        )
                      ])
                    : _c(
                        "ul",
                        {
                          staticStyle: {
                            padding: "0",
                            margin: "0",
                            "list-style": "none"
                          },
                          attrs: { type: "none" }
                        },
                        _vm._l(item.grids, function(grid) {
                          return _c("li", { key: grid.id }, [
                            _vm._v(" " + _vm._s(grid.group.course.name) + " ")
                          ])
                        }),
                        0
                      )
                ]
              }
            },
            {
              key: "item.shifts",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm.isUniqueShifts(item)
                    ? _c("span", [
                        _vm._v(
                          " " + _vm._s(item.grids[0].group.shift.name) + " "
                        )
                      ])
                    : _c(
                        "ul",
                        {
                          staticStyle: {
                            padding: "0",
                            margin: "0",
                            "list-style": "none"
                          },
                          attrs: { type: "none" }
                        },
                        _vm._l(item.grids, function(grid) {
                          return _c("li", { key: grid.id }, [
                            _vm._v(" " + _vm._s(grid.group.shift.name) + " ")
                          ])
                        }),
                        0
                      )
                ]
              }
            },
            {
              key: "item.work_load",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm.isUniqueShifts(item)
                    ? _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              item.grids[0].work_load_type.initials +
                                "=" +
                                item.grids[0].work_load
                            ) +
                            " "
                        )
                      ])
                    : _c(
                        "ul",
                        {
                          staticStyle: {
                            padding: "0",
                            margin: "0",
                            "list-style": "none"
                          },
                          attrs: { type: "none" }
                        },
                        _vm._l(item.grids, function(grid) {
                          return _c("li", { key: grid.id }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  grid.work_load_type.initials +
                                    "=" +
                                    grid.work_load
                                ) +
                                " "
                            )
                          ])
                        }),
                        0
                      )
                ]
              }
            },
            {
              key: "item.grids",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "ul",
                    {
                      staticStyle: {
                        padding: "0",
                        margin: "0",
                        "list-style": "none"
                      },
                      attrs: { type: "none" }
                    },
                    _vm._l(item.grids, function(grid) {
                      return _c(
                        "li",
                        { key: grid.id },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "span",
                                          _vm._g(
                                            _vm._b({}, "span", attrs, false),
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              _vm._s(_vm.getTextJoin(grid))
                                            )
                                          ]
                                        ),
                                        grid.subGroup
                                          ? _c("small", [
                                              _c("br"),
                                              _vm._v(_vm._s(grid.subGroup.name))
                                            ])
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      grid.group.name +
                                        " | " +
                                        grid.discipline_name
                                    ) +
                                    " "
                                ),
                                grid.subGroup
                                  ? _c("small", [
                                      _c("br"),
                                      _vm._v(_vm._s(grid.subGroup.name))
                                    ])
                                  : _vm._e()
                              ])
                            ]
                          ),
                          grid.students && grid.students > 0
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "strong",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticStyle: {
                                                      cursor: "help"
                                                    }
                                                  },
                                                  "strong",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    " (" + grid.students + ")"
                                                  )
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [_c("span", [_vm._v(" Número de Alunos ")])]
                              )
                            : _vm._e(),
                          grid.join && grid.join.main
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticStyle: {
                                                      cursor: "help"
                                                    },
                                                    attrs: {
                                                      "x-small": "",
                                                      icon: ""
                                                    }
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      "x-small": "",
                                                      color: "amber",
                                                      btn: ""
                                                    }
                                                  },
                                                  [_vm._v("mdi-star-half-full")]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [_c("span", [_vm._v(" Turma Principal ")])]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    }),
                    0
                  )
                ]
              }
            }
          ],
          null,
          true
        )
      }),
      _c("sys-register-join", {
        attrs: {
          settings: _vm.settings,
          title: "Cadastrar Junção",
          model: {},
          show: _vm.showFormIndividual,
          maxWidth: "85%"
        },
        on: {
          onCompleted: function($event) {
            return _vm.getData()
          },
          "update:show": function($event) {
            _vm.showFormIndividual = $event
          }
        }
      }),
      _c("sys-register-join", {
        attrs: {
          settings: _vm.settings,
          model: _vm.model,
          show: _vm.showForm,
          maxWidth: "85%"
        },
        on: {
          onCompleted: function($event) {
            return _vm.getData()
          },
          "update:show": function($event) {
            _vm.showForm = $event
          }
        }
      }),
      _c("emc-alert-modal", {
        attrs: { text: _vm.text, disabled: _vm.deleting, show: _vm.show },
        on: {
          "update:show": function($event) {
            _vm.show = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "error",
                      loading: _vm.deleting,
                      disabled: _vm.deleting
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.deleteItem()
                      }
                    }
                  },
                  [
                    _vm._v("Excluir "),
                    _c("v-icon", { attrs: { right: "", dark: "" } }, [
                      _vm._v("mdi-delete")
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("emc-alert-modal", {
        attrs: {
          text:
            "Essa ação irá excluir todos os itens da Turma Gerencial no Totvs (professor, horário, vínculo das turmas gerenciadas, turma disciplina gerencial e turma gerencial). Confirma a exclusão?",
          disabled: _vm.deleting,
          show: _vm.showDeleteTotvs
        },
        on: {
          "update:show": function($event) {
            _vm.showDeleteTotvs = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "error",
                      loading: _vm.deleting,
                      disabled: _vm.deleting
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.removeTotvs(_vm.itemDeleteTotvs)
                      }
                    }
                  },
                  [
                    _vm._v("Excluir "),
                    _c("v-icon", { attrs: { right: "", dark: "" } }, [
                      _vm._v("mdi-delete")
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("emc-alert-snackbar", {
        attrs: {
          show: !!_vm.message.length,
          message: _vm.message,
          color: _vm.error ? "error" : "success"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }